<template>
    <div class="mr-3 mb-[2px]">
        <el-badge :value="messagesNumber" :hidden="!messagesNumber">
            <fa-icon :icon="['far', 'envelope']" class="text-xl text-gray-600" />
        </el-badge>
    </div>
</template>
<script>
export default {
    computed: {
        messagesNumber() {
            let count = 0;
            this.$store.state.roomsUnreadMessages.forEach(item => {
                count += item.count;
            });
            return count;
        },
    },
};
</script>
